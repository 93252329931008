import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Painting() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Painting Estimation</h2>
            <div><p>Home / Services / Painting Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Painting Estimation Service: Precision in Every Stroke, Partnership in Every Project</h4>
                <p>Welcome to Suryagroup, where we turn the art of painting estimation into a science! Our detailed painting estimation services provide you with a crystal-clear picture of materials, labor, and costs for any painting project. Whether you're jazzing up a cozy home or transforming a sprawling commercial space, we've got your back (and your walls) covered.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What's in Our Color Palette of Services? </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff: No Drop Left Behind</h5>
              <p>Our painting takeoff services ensure you have the right amount of everything - because nobody likes a mid-project paint run! We calculate:
                <ul>
                  <li>Paint quantities (from interior coziness to exterior durability, and those fancy specialty coatings)</li>
                  <li>Primers, sealants, and coatings (the unsung heroes of a great paint job)</li>
                  <li>Surface preparation materials (because smooth walls make happy painters)</li>
                  <li>Painting tools and equipment recommendations (we'll make sure you're armed and ready)</li>
                </ul>
              </p>
            </div>
            
            <div>
              <h5>2. Accurate Surface Area Calculation: Every Square Inch Counts</h5>
              <p>We leave no surface unmeasured, providing detailed calculations for:
                <ul>
                  <li>Walls (interior and exterior - we've got you covered, inside and out)</li>
                  <li>Ceilings (because looking up shouldn't be overlooked)</li>
                  <li>Doors, windows, and trims (all those details that make a big difference)</li>
                  <li>Fences, decks, and other exterior surfaces (giving 'the great outdoors' a makeover)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation: Because Time is Money, and Skill is Priceless</h5>
              <p>We don't just count Paint Gallons; we account for the hands that wield the brushes:
                <ul>
                  <li>Hours required for each phase (from prep to the final 'ta-da!' moment)</li>
                  <li>Skilled labor pricing tailored to your project (because not all paint jobs are created equal)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support: Keeping Your Finances as Smooth as Your Finish</h5>
              <p>We provide comprehensive cost estimates that help you stay in the black while painting the town red (or any color, really):
                <ul>
                  <li>Detailed itemization of material and labor costs (no surprises here)</li>
                  <li>Budget projections to keep your wallet happy</li>
                  <li>Customized quotations for your clients (transparency is our favorite shade)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Cost Tracking: Because Numbers Should Be as Clear as Your Coat of Paint</h5>
              <p>Managing expenses is a breeze with our organized invoicing services:
                <ul>
                  <li>Itemized material lists with costs (know where every penny goes)</li>
                  <li>Ongoing cost tracking (keeping your budget from going off the rails)</li>
                  <li>Clear, professional invoices (making your accountant's job a little easier)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Why Choose Our Painting Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Precision & Detail:  </strong>We're as meticulous as a artist with a fine-tipped brush</li>
                  <li><strong>Cost Efficiency: </strong>  Helping you save green while you paint the town... well, any color you like</li>
                  <li><strong>Expert Insights: </strong>Our team doesn't just estimate; we bring years of wisdom to your project</li>
                  <li><strong>Customized Solutions:  </strong>Because your project is as unique as a snowflake (but hopefully not as fleeting)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our Painting Estimation Services?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Ensure your projects are as seamless as a perfect paint line</li>
                  <li><strong> Subcontractors: </strong> Focus on creating masterpieces while we crunch the numbers</li>
                  <li><strong>Project Managers: </strong>Gain control that's as smooth as a freshly painted surface</li>
                  <li><strong>Developers:   </strong>Stay on budget and schedule, from first coat to final touch-up</li>
                  <li><strong>Architects:  </strong> Bring your vision to life, down to the last color-matched detail</li>
                  <li><strong> Engineers:</strong> Align your structural plans with our precise calculations</li>
                  <li><strong> Quantity Surveyors: </strong> Enhance your analysis with our detailed breakdowns</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Add Some Color to Your Next Project?</h5>
              <p>
              Let us handle the complexities of your painting estimations, so you can focus on turning spaces into works of art. Contact us for a free consultation and see how our expertise can paint your next project in the best light possible.

Remember, in the world of painting estimation, we're the perfect blend of Van Gogh's passion and Einstein's precision. Let's create something beautiful together!
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
