import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Demolition() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Demolition Takeoff Service</h2>
            <div><p>Home / Services / Demolition Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Demolition Takeoff Service: Bringing Down Buildings, Raising Up Expectations!</h4>
                <p>Welcome to Surya Group, where we turn the chaos of demolition into a symphony of precision! Whether you're knocking down walls for a cozy home renovation or clearing the way for a skyscraper, our demolition takeoff services ensure your project starts with a bang - and ends with a round of applause.</p>
              </div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What's in Our Demolition Toolbox?: </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff: We Leave No Stone Unturned (Unless It's Scheduled for Demolition) </h5>
              <p>Our demolition takeoff service breaks down your project faster than a wrecking ball:
                <ul>
                  <li> Building structures: walls, floors, roofs, foundations (we count every brick before it falls)</li>
                  <li> Interior elements: drywall, flooring, partitions, and ceilings (saying goodbye to the old to make way for the new)</li>
                  <li> Mechanical systems: HVAC, electrical, and plumbing components (because even buildings need a clean break)</li>
                  <li> Specialized materials: hazardous materials and recyclables (we sort the good, the bad, and the ugly)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5> 2. Area and Volume Calculations: Measuring Your Project's Potential (or Lack Thereof)</h5>
              <p>We measure every nook and cranny, ensuring nothing escapes our demolition radar:
                <ul>
                  <li> Concrete and masonry structures (from sturdy walls to stubborn foundations)</li>
                  <li> Building footprints, height, and structural elements (we size up your takedown)</li>
                  <li> Interior demolitions for renovations or remodels (out with the old, in with the new!)</li>
                  <li> Site clearing, including excavation and disposal (leaving no trace behind)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor & Equipment Cost Estimation: Counting Every Swing of the Sledgehammer</h5>
              <p> We factor in all the muscle and machinery needed to bring your vision to life:
                <ul>
                  <li> Labor hours for manual or mechanical demolition (from gentle taps to mighty swings)</li>
                  <li> Specialized labor for hazardous material handling (safety first, always!)</li>
                  <li> Heavy equipment costs: bulldozers, excavators, wrecking balls, and cranes (the big toys for big jobs)</li>
                  <li> Site cleanup, debris removal, and disposal fees (because a clean slate is the best foundation)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support: Keeping Your Finances from Crumbling</h5>
              <p> Our budgeting services ensure your project doesn't demolish your wallet:
                <ul>
                  <li> Detailed cost breakdowns (we crunch numbers harder than we crush concrete)</li>
                  <li> Value engineering suggestions (maximizing demolition efficiency without breaking the bank)</li>
                  <li> Customizable quotations (impressing your clients with transparency clearer than a dust-free demo site)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Disposal Tracking: Clearing the Debris from Your Finances</h5>
              <p>We keep your project's financial picture as clean as a post-demo site:
                <ul>
                  <li> Itemized lists of materials and disposal costs (tracking every brick and beam)</li>
                  <li> Ongoing cost tracking (keeping your budget from collapsing under pressure)</li>
                  <li> Professional invoicing that lays a solid foundation for easy financial management</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Swing with Our Demolition Takeoff Services?</h5>
              <p>
                <ul>
                  <li><strong>Accuracy That Hits the Mark:  </strong>  Our estimates are as precise as a laser-guided wrecking ball</li>
                  <li><strong>Safety First, Second, and Third:</strong> We factor in safety measures that would make a bubble wrap enthusiast proud</li>
                  <li><strong> Time & Cost Efficiency: </strong> Our takeoffs help you demolish on time and on budget</li>
                  <li><strong> Tailored Solutions:  </strong> From delicate renovations to full-scale takedowns, we customize our estimates to fit your project like a perfectly calibrated explosive charge</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Gets to Wield Our Demolition Wisdom?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Keep your projects crushing it from first swing to final sweep</li>
                  <li><strong>Subcontractors: </strong> Focus on perfecting your demolition dance while we handle the number crunching</li>
                  <li><strong>Project Managers: </strong> Maintain control that's as solid as the structures you're taking down</li>
                  <li><strong>Developers: </strong> Plan your takedowns with the precision of a demolition choreographer</li>
                  <li><strong>Architects:  </strong> Ensure your designs have room to grow by accurately estimating what needs to go</li>
                  <li><strong>Engineers:  </strong>  Align your structural plans with our precise calculations (it's a perfect implosion!)</li>
                  <li><strong>Quantity Surveyors:</strong> Quantity Surveyors: Enhance your analysis with our detailed breakdowns (we'll help you quantify the qualifiable)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Demolish Estimation Uncertainty?</h5>
              <p>Let us handle the complexities of your demolition takeoffs, so you can focus on bringing down the house (literally). Contact us today for a free consultation and discover how our expertise can be the wrecking ball your project needs to swing into action.
                  Remember, in the world of demolition estimation, we're the perfect blend of surgical precision and controlled chaos. Let's create some space for something spectacular!</p>
            </div>
           


          </div> 
        </div>
    </section>  
  )
}
