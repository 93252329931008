import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Concrete() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Concrete Estimation</h2>
            <div><p>Home / Services / Concrete Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Concrete Estimation Service: Rock-Solid Estimates for Unshakeable Foundations</h4>
                <p>Welcome to Surya Group, where we turn the complexities of concrete estimation into a smooth pour of precision! Whether you're laying the groundwork for a cozy home or erecting a towering skyscraper, our estimates are as solid as the structures you're building.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What's in Our Concrete Mix of Services?</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff: Every Grain of Sand Counts</h5>
              <p>We don't just estimate; we calculate with the precision of a chemist mixing the perfect formula:
                <ul>
                  <li>Concrete volume calculations (in cubic yards or meters - we speak your language!)</li>
                  <li>Rebar, mesh, and reinforcement materials (because every structure needs a good backbone)</li>
                  <li>Formwork materials and accessories (shaping your concrete dreams into reality)</li>
                  <li>Admixtures, curing agents, and sealants (the secret ingredients for concrete perfection)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>2. Measurements That Would Make Archimedes Proud</h5>
              <p>We measure every nook and cranny to ensure your project is built on a foundation of accuracy:
                <ul>
                  <li>Foundations, slabs, and footings (the unsung heroes of every structure)</li>
                  <li>Columns, beams, and walls (from simple supports to architectural marvels)</li>
                  <li>Curbs, sidewalks, driveways, and other flatwork (because even the ground you walk on matters)</li>
                  <li>Complex structures like stairs, ramps, and retaining walls (we love a good challenge!)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation: Accounting for Every Trowel and Finish</h5>
              <p>We don't just calculate materials; we factor in the artistry of concrete work:
                <ul>
                  <li>Hours for site prep, formwork, pouring, and finishing (from first dig to final polish)</li>
                  <li>Specialized labor for reinforced concrete, tilt-up panels, or precast installations (because some concrete work is a true art form)</li>
                  <li>Projections for curing, cutting, and polishing (giving your concrete that perfect finish)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support: Keeping Your Finances as Solid as Your Concrete</h5>
              <p>Our budgeting services ensure your project stands on firm financial ground:
                <ul>
                  <li>Itemized costs for all materials and labor (no hidden pebbles in our estimates)</li>
                  <li>Value engineering options (maximizing quality while minimizing waste - it's like poetry in concrete)  </li>
                  <li>Crystal-clear quotations (impressing your clients with transparency harder than a 28-day cure)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Tracking: Clarity Stronger than Rebar</h5>
              <p>We keep your project's financial picture as clear as freshly poured concrete:
                <ul>
                  <li>Itemized lists of all materials (track every yard of concrete and foot of rebar)</li>
                  <li>Ongoing cost tracking (keeping your budget from cracking under pressure)  </li>
                  <li>Professional invoicing that sets a solid foundation for easy financial management</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Pour Your Trust into Our Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Precision & Reliability:   </strong>We measure twice and calculate thrice, so your concrete plans are set in stone</li>
                  <li><strong> Time-Saving Expertise:</strong> Our efficient process gives you more time to focus on turning plans into rock-solid reality</li>
                  <li><strong>Cost Efficiency: </strong> Stay on budget without compromising on quality (have your concrete and place it too!)</li>
                  <li><strong>Tailored Solutions:   </strong>From simple slabs to complex structures, we customize our estimates to fit your project like a perfect pour</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Gets to Build on Our Estimation Foundation?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Keep your projects flowing smoothly from groundbreaking to grand opening</li>
                  <li><strong>Subcontractors: </strong> Focus on perfecting your concrete craft while we handle the number crunching</li>
                  <li><strong>Project Managers: </strong> Maintain control that's as unshakeable as a well-laid foundation</li>
                  <li><strong>Developers:   </strong>  Keep your projects on track, from first sketch to final trowel</li>
                  <li><strong>Architects: </strong>Bring your grandest concrete designs to life, down to the last aggregate</li>
                  <li><strong>Engineers:</strong>  Align your structural plans with our precise calculations (it's a perfect mix!)</li>
                  <li><strong>Quantity Surveyors:  </strong>Enhance your analysis with our detailed breakdowns (we'll help you set things in stone)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Lay the Groundwork for Estimation Excellence?</h5>
              <p>
              Let us handle the intricacies of your concrete estimations, so you can focus on building structures that stand the test of time. Contact us today for a free consultation and discover how our expertise can be the solid foundation your project needs.

Remember, in the world of concrete estimation, we're the perfect blend of scientific precision and artistic vision. Let's create something that not only stands strong - but stands out!
</p>
            </div>
          </div>  

        </div>
    </section>  
  )
}
