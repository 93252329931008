import React from 'react'
import '../styles/Navbar.css';
import '../styles/Mediaquerry.css';

import '../javascript/nav.js'

import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';

console.log("hello")

export default function Navbar() {
  return (
    <>
    <navbar>
        <div id='top-bar'>
            <div> <a className='link-btn' href='mailto:email@example.com'>Info@suryagroup.us</a></div>
            <div><a  className='link-btn' href='https://wa.me/9634377090'>+91 9634377090</a></div>
        </div>

        <header>
            <ul className='nav-ele'>
                <li><Link className=' nav-link link-btn active' to="/">Home </Link></li>
                <li  id='drop-access'><div className='link-btn' style={{lineHeight:"100px"}}>Explore by regions </div>
                    <div id='visiblity' className='dropdown-bar'>
                        <ul>
                            <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">West</Link></li>
                            <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">Mid West</Link></li>
                            <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">South West</Link></li>
                            <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">South East</Link></li>
                            <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">North East</Link></li>
                        </ul>
                    </div>
                </li>
                <div id='logo'><img src={logo}  alt='suryagroup.us'/></div>
                <li><Link className=' nav-link link-btn ' to="/blog">Hub</Link></li>
                <li><Link className=' nav-link link-btn ' to="/contact-us">Contact Us</Link></li>

                <div id='sign-btn'>
                    <Link className='cmn-btn cmn-btn-dark' to= "/login">Admin Log In</Link>
                </div>
            </ul>
            
        </header>
    </navbar>


</>

  )
}







