import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Siding() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Siding Estimation</h2>
            <div><p>Home / Services / Siding Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Siding Estimation Services: Precision Meets Personalization</h4>
                <p>Welcome to Surya Group's Siding Estimation Services! We are thrilled to offer you our expertise in providing detailed, accurate, and tailored estimates for your siding projects. Whether you are working on cozy homes or sprawling commercial complexes, we have got you covered - quite literally!</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What Sets Our Siding Estimates Apart? </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Material Takeoffs That Leave No Panel Unturned</h5>
              <p>Our team does not just count siding panels; we meticulously analyse every aspect of your project. Here is what you can expect:
                <ul>
                  <li>Comprehensive breakdowns for all siding types (vinyl, Fiber cement, wood - you name it, we estimate it!)</li>
                  <li>Detailed lists of trim, soffit, and fascia materials (because the details matter)</li>
                  <li>Fasteners, sealants, and weatherproofing components (we have got your back - and your walls)</li>
                  <li>All the corner pieces, window trims, and accessories you will need (no mid-project hardware store runs, we promise)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>2. Surface Area Calculations That Would Make Mathematicians Proud</h5>
              <p>We leave no stone (or in this case, no wall) unmeasured::
                <ul>
                  <li>Precise wall surface areas on all sides (even those tricky corners)</li>
                  <li>Gables, dormers, and eaves (the architectural features that add character - and complexity)</li>
                  <li>Doors, windows, and unique architectural elements (because your building isn't just a box)</li>
                  <li>Multi-story and intricate building configurations (we love a good challenge!)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Costs Estimated Down to the Last Nail</h5>
              <p>We don't just focus on materials; we provide a clear picture of the labor involved:
                <ul>
                  <li>Detailed hours for removal (if needed), prep work, and installation</li>
                  <li>Costs for specialized labor (because some siding layouts are true works of art)</li>
                  <li>Installation estimates for all those important extras like soffits and fascia</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting That Keeps Your Wallet Happy</h5>
              <p>We are here to help you stay on budget without compromising on quality::
                <ul>
                  <li>Cost-saving suggestions (because who does not love saving money?)</li>
                  <li>Transparent breakdowns of material and labor costs.</li>
                  <li>Customizable quotations for your clients (building trust, one estimate at a time).</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing That Won't Give You a Headache</h5>
              <p>Keep track of your project finances with ease:
                <ul>
                  <li>Clear, itemized lists of all materials (no mysterious entries here).</li>
                  <li>Real-time updates on material usage (because plans can change).</li>
                  <li>Professional invoicing to keep your finances as smooth as your new siding.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Why Choose Surya Group for Your Siding Estimates?</h5>
              <p>
                <ul>
                  <li><strong>Accuracy You Can Count On:  </strong>Every panel, every trim piece, accounted for with precision.</li>
                  <li><strong>Efficiency That Saves Time and Materials: </strong>  Less waste, more productivity - it's a win-win!</li>
                  <li><strong>Solutions That Love Your Budget: </strong> We help you optimize without cutting corners.</li>
                  <li><strong>Tailored Service, Just for You:  </strong>Because your project is unique, and your estimate should be too.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Benefits from Our Siding Savvy?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Streamline your projects with our spot-on estimates</li>
                  <li><strong>Subcontractors: </strong>Focus on installation excellence while we handle the number-crunching</li>
                  <li><strong>Project Managers: </strong>Keep your siding projects on track and on budget</li>
                  <li><strong>Developers:    </strong>Impress clients and investors with accurate projections</li>
                  <li><strong>Architects: </strong> Bring your design visions to life with precise material estimates</li>
                  <li><strong>Engineers:</strong> Align structural plans with siding requirements seamlessly</li>
                  <li><strong>Quantity Surveyors: </strong>  Enhance your valuations with our detailed breakdowns</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Wrap Your Project in Precision?</h5>
              <p>
              Let's face it - estimating siding can be as tricky as nailing jelly to a wall. But with Surya Group, you've got a partner who's got your back (and your front, sides, and roof too!).

Don't let estimation headaches delay your project. Reach out today for a free consultation, and let's get your siding project off to a fantastic start. With Surya Group, you're not just getting an estimate – you're gaining a dedicated partner in your project's success.

Remember, in the world of siding estimation, we've nailed it down to a science. Let's create something beautiful together!
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
