import React from 'react'
import '../../styles/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
  return (
    <section>
        <div className='main-width'>


            <div className='com-background' id='contact-back'>
                <div className='com-container'>
                    <h1>Contact Us</h1>
                    <p>Home / Contact Us</p>
                </div>
            </div>

            
                <div className='contact-menu'>

                    <div className='contact-inner'>
                        <div className = 'com-logo'><FontAwesomeIcon icon={faEnvelope} /></div>  
                        <h4>Email (Landline) </h4>    
                        <a class=" nav-link link-btn " href="mailto:Info@Estimation.us">Info@suryagroup.us</a>
                
                    </div>
                    
                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faPhone} /></div>
                        <h4>Call (Landline) </h4>
                        <a class=" nav-link link-btn " href="tel:+919634377090">+91 9634377090</a>
                        <a class=" nav-link link-btn " href="tel:+918077414663">+91 8077414663</a>

                    </div>

                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faLocationDot} /></div>
                        <h4>Our Office Location</h4>
                        <address>
                            012 Main Street, <br/>Bageshwar,<br/> Uttarakhand,<br/> 263642,<br/> India
                        </address>

                    </div>
                </div>
            

        </div>
    </section>
  )
}
