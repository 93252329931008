import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Lumber() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Lumber Estimation</h2>
            <div><p>Home / Services / Lumber Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Lumber Estimation Service: Accurate, reliable & tailored for your projects</h4>
                <p>At Surya Group, we specialize in delivering precise and comprehensive lumber estimation services that cater to the unique needs of contractors and all other construction specialists. Whether you're working on a residential, commercial, or industrial project, our ability ensures that you have a clear and accurate understanding of the lumber materials required for every phase of construction.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What We Offer:</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff:</h5>
              <p>Our team meticulously analyses your project blueprints to provide a detailed breakdown of the lumber needed. From framing, decking, and sheathing to custom millwork, we ensure that no material is overlooked. Our takeoff process includes:
                <ul>
                  <li>Dimensional lumber Specie consider</li>
                  <li>Engineered wood products (LVL, I-joists, LSL, Parallam, Glulam)</li>
                  <li>Plywood, OSB, and sheathing materials</li>
                  <li>Immaculate calculation for Simpson Hardware.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>2. Accurate Quantity Calculation:</h5>
              <p>We provide a precise count of every piece of lumber needed, ensuring that your project is supplied with exactly the right amount. This not only helps reduce material waste but also keeps your project within budget. Our detailed quantity calculations include:
                <ul>
                  <li>Piece-by-piece counts</li>
                  <li>Generic estimations (Square Feet, Linear Feet)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Budgeting & Quotation Support:</h5>
              <p>Understanding the financial side of material procurement is essential for successful project management. Our lumber estimation service includes detailed cost projections and budgeting aid, helping you:
                <ul>
                  <li>Create right project quotations</li>
                  <li>Plan for material costs upfront</li>
                  <li>Manage resources effectively to avoid overspending</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Invoicing & Material Cost Tracking:</h5>
              <p>Tracking material expenses can be complex, especially for larger projects. We streamline this process by offering clear and organized invoicing services, which include:
                <ul>
                  <li>Itemized lists of all lumber materials</li>
                  <li>Pricing breakdown for easy budget tracking</li>
                  <li>Ongoing updates to ensure you stay on top of material costs throughout the project</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Choose Our Lumber Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Precision & Accuracy:  </strong> With years of experience in the construction estimation field, we ensure that every detail is covered, leaving no room for costly errors.</li>
                  <li><strong>Tailored Solutions:</strong> We understand that every project is different. Our estimations are customized to fit the specific needs of your job, whether it's a small residential build or a large commercial project.</li>
                  <li><strong>Time saving: </strong>Save hours of manual calculations and focus on what you do best—building. Our professional estimation team takes care of the intricate details.</li>
                  <li><strong>Cost-Effective:   </strong>Our accurate takeoffs and budgeting support help you refine your materials, reducing waste and ensuring that your project is still on budget.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our Lumber Estimation Services?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Ensure efficient project management with precise material estimates that keep your construction timeline and budget on track.</li>
                  <li><strong>Subcontractors: </strong> Simplify your workflow with detailed lumber counts and cost projections, allowing you to focus on delivering high-quality craftsmanship.</li>
                  <li><strong>Project Managers: </strong> Gain full control over project costs and resources with correct, real-time data on material requirements and budgeting.</li>
                  <li><strong>Developers:   </strong> Refine your project planning and decision-making with comprehensive material takeoffs that help manage costs and minimize delays.</li>
                  <li><strong> Architects: </strong> Receive reliable material estimates that align with your design vision, ensuring your projects go ahead smoothly and without unnecessary interruptions.</li>
                  <li><strong>Engineers:</strong>  Use precise and technical estimations to ensure structural integrity and material efficiency throughout the building process.</li>
                  <li><strong>Quantity Surveyors:  </strong>Enhance your cost management strategies with detailed and exact material breakdowns, helping you deliver projects within budget.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Get Started Today!</h5>
              <p>
              Let us handle the complex task of lumber estimation while you focus on delivering exceptional construction outcomes. Contact us today for a free consultation and see how our expert services can help streamline your next project.
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
