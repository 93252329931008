import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Roofing() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Roofing Estimation</h2>
            <div><p>Home / Services / Roofing Estimation</p></div>
          </div>

          <div className='service-container'>
            <div className='ser-flex'>
              <div>
                <h4>Surya Group: Excellence in Roofing Estimation Services</h4>
                <strong>Precision and Reliability for Your Roofing Projects</strong>
                <p>Welcome to Surya Group, your trusted partner in comprehensive roofing estimation services. We specialize in providing accurate, detailed estimates for residential, commercial, and industrial roofing projects. Our goal? To ensure your project is completed on time and within budget.</p>
              </div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>Our Core Services</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Detailed Material Takeoff: </h5>
              <p> We provide a thorough breakdown of all required materials, including:
                <ol type='a'>
                  <li> Roofing materials (shingles, metal sheets, tiles, membrane systems)</li>
                  <li> Underlayment, insulation, and waterproofing</li>
                  <li> Flashing, drip edges, ridge caps, and ventilation systems</li>
                  <li> Fasteners, sealants, and accessories</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>2. Precise Area and Pitch Calculation</h5>
              <p> Our team accurately measures roof area and pitch, accounting for:
                <ol type='a'>
                  <li>Various roof designs (flat, sloped, or complex)</li>
                  <li>Eaves, valleys, hips, ridges, and dormers</li>
                  <li>Skylights, chimneys, vents, and other penetrations</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>3. Comprehensive Labor Cost Estimation :</h5>
              <p>We offer detailed labor cost estimates covering:
                <ol type='a'>
                  <li>Hours for roof removal, preparation, and installation</li>
                  <li>Specialized labor for complex designs</li>
                  <li>Installation of accessories (flashing, gutters, ventilation)</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>4. Strategic Budgeting & Quotation Support :</h5>
              <p>Stay on budget with our clear cost breakdowns:
                <ol type='a'>
                  <li>Itemized lists of materials and labor costs</li>
                  <li>Cost-saving recommendations</li>
                  <li>Customizable client quotations</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>5. Efficient Invoicing & Cost Tracking:</h5>
              <p>  Manage your project finances effectively with:
                <ol type='a'>
                  <li>Detailed material lists</li>
                  <li>Ongoing cost tracking</li>
                  <li>Professional invoicing</li>
                </ol>
              </p>
            </div>

           

            <div>
              <h5>The Surya Group Advantage</h5>
              <p>
                <ul>
                  <li><strong>Unparalleled Accuracy:  </strong>  Our estimates are precise, minimizing costly surprises.</li>
                  <li><strong>Time-Saving Expertise:</strong> Focus on your core tasks while we handle the complex calculations.</li>
                  <li><strong>Cost Efficiency: </strong>  We help you optimize material usage and reduce waste.</li>
                  <li><strong>Tailored Solutions:  </strong>  From residential to large-scale commercial projects, we customize our approach to fit your needs.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Benefits from Our Services?</h5>
              <p>
                <ul>
                  <li>General Contractors</li>
                  <li>Subcontractors</li>
                  <li>Project Managers</li>
                  <li>Developers</li>
                  <li>Architects</li>
                  <li>Engineers</li>
                  <li>Quantity Surveyors</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Why Choose Surya Group?</h5>
              <p>Let's face it - roofing projects can be a real headache. But they don't have to be! With Surya Group, you're not just getting numbers on a page. You're getting a partner who understands the ins and outs of roofing estimation.

We've seen it all - from simple residential re-roofs to complex commercial projects that would make your head spin. And you know what? We love every minute of it. Why? Because we're passionate about helping you succeed.</p>
            </div>
            <div>
              <h5 className='offer-ser'>Ready to Get Started?</h5>
                <p>Don't let roofing estimation stress keep you up at night. Reach out to Surya Group today, and let's talk about how we can make your next roofing project a resounding success.

                  Remember, a great roof starts with a great estimate. Let's build something amazing together!

                  Contact us now for a free consultation. Your future self will thank you!</p>
            </div>
          </div>  

        </div>
    </section>  
  )
}
