import React from 'react'

import '../../styles/Home.css'
export default function Home() {
  return (
    <section>
      <div className='main-width' style={{
    padding: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
      <div id="carouselExampleRide" className="carousel slide" data-bs-ride="true">
        <div className="carousel-inner">

            <div className="admin-back">
              <div className='admin-item'>  
                <h1>Welcome to Admin Portal of Surya Group</h1>
                <p>At Surya Group, we provide clear and accurate construction estimates with a personal touch. Using the latest tools and technology, we make your project planning and budgeting easy and precise. Let us help you bring your vision to life with reliable and innovative solutions.</p>
              </div>
            </div>

            

          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
          </button>
        </div>

        </div>
      </section>
  )
}
